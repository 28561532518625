import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import ProgressBar from 'packages/common/base/components/ProgressBar';
import TextInput from 'packages/common/base/components/TextInput';
import TextButton from 'packages/common/base/components/TextButton';
import {
    DarkButtonStyled,
    RedButtonStyled,
    CheckStyled,
    Heading2Styled,
} from 'styles/common/marketing';
import ArrowRight from 'packages/common/assets/svg/arrow-right.svg';
import ArrowLeft from 'packages/common/assets/svg/arrow-left.svg';
import CheckIcon from 'packages/common/assets/svg/check.svg';
import AlertIcon from 'packages/common/assets/svg/alert.svg';
import emailValidator from 'email-validator';
import { useForm } from '@formspree/react';
import { transparentize } from 'polished';

export interface ApplicationSubmission {
    type: UserType;
    name: string;
    email: string;
    social: string;
    referralSource: string;
}

interface CreatorApplication {
    className?: string;
    onSubmit: (application: ApplicationSubmission) => void;
    onClose: () => void;
    onError: (errors: any[]) => void;
    onSignup: () => void;
}

type InputField = 'name' | 'email' | 'social' | 'userType';
type UserType = 'creator' | 'leader' | 'fan';

const CreatorApplication: React.FunctionComponent<CreatorApplication> = (props) => {
    const { className, onSubmit, onClose, onError, onSignup } = props;

    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState<UserType>(null);
    const [social, setSocial] = useState('');
    const [referralSource, setReferralSource] = useState('');
    const [errors, setErrors] = useState(new Set<InputField>(new Set()));

    const [state, handleSubmit] = useForm('meqnvazo');

    const validateStep = () => {
        const tempErrors = new Set(errors);

        switch (step) {
            case 1:
                if (!name) {
                    tempErrors.add('name');
                }

                if (!email || !emailValidator.validate(email)) {
                    tempErrors.add('email');
                }

                break;
            case 2:
                if (!userType) {
                    tempErrors.add('userType');
                }

                break;
            case 3:
                if (!social) {
                    tempErrors.add('social');
                }

                break;
            default:
        }

        setErrors(tempErrors);

        return tempErrors.size === 0;
    };

    const clearError = (type: InputField) => {
        const tempErrors = new Set(errors);

        tempErrors.delete(type);

        setErrors(tempErrors);
    };

    useEffect(() => {
        if (state.succeeded) {
            setStep(5);
        }
    }, [state.succeeded]);

    useEffect(() => {
        if (state.errors.length > 0) {
            onError(state.errors);
        }
    }, [state.errors, onError]);

    return (
        <CreatorApplicationStyled
            className={className}
            noValidate
            onSubmit={(e) => {
                if (step === 4) {
                    if (!validateStep()) return;
                    handleSubmit(e);
                    onSubmit({
                        type: userType,
                        name,
                        email,
                        social,
                        referralSource,
                    });
                } else {
                    e.preventDefault();
                    if (!validateStep()) return;
                    setStep(step + 1);
                }
            }}
        >
            <div>
                <input type="hidden" name="type" value={userType} />
                <input type="hidden" name="name" value={name} />
                <input type="hidden" name="email" value={email} />
            </div>
            <ContentsStyled>
                {step === 1 && (
                    <>
                        <HeadingStyled>Apply to become a Playback Creator.</HeadingStyled>
                        <TextInputContainerStyled>
                            <TextInputStyled
                                label="Full name"
                                inputSize="lg"
                                type="text"
                                onChange={(e) => {
                                    setName(e.target.value);
                                    clearError('name');
                                }}
                                placeholder="LeBron James"
                                hasError={errors.has('name')}
                            />
                            {errors.has('name') && (
                                <TextInputErrorStyled>
                                    Looks like you forgot to include your name!
                                </TextInputErrorStyled>
                            )}
                        </TextInputContainerStyled>
                        <TextInputContainerStyled>
                            <TextInputStyled
                                label="Email address"
                                inputSize="lg"
                                type="email"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    clearError('email');
                                }}
                                placeholder="lbj@lakers.com"
                                hasError={errors.has('email')}
                            />
                            {errors.has('email') && (
                                <TextInputErrorStyled>
                                    Please enter a valid email address.
                                </TextInputErrorStyled>
                            )}
                        </TextInputContainerStyled>
                    </>
                )}
                {step === 2 && (
                    <>
                        <HeadingStyled>
                            Are you a creator, community leader, or just a fan?
                        </HeadingStyled>
                        <UserButtonsStyled>
                            <UserButtonStyled
                                active={userType === 'creator'}
                                type="button"
                                onClick={() => {
                                    setUserType('creator');
                                }}
                            >
                                {userType === 'creator' && (
                                    <CheckStyled>
                                        <CheckIcon />
                                    </CheckStyled>
                                )}
                                Creator
                            </UserButtonStyled>
                            <UserButtonStyled
                                active={userType === 'leader'}
                                type="button"
                                onClick={() => {
                                    setUserType('leader');
                                }}
                            >
                                {userType === 'leader' && (
                                    <CheckStyled>
                                        <CheckIcon />
                                    </CheckStyled>
                                )}
                                Community Leader
                            </UserButtonStyled>
                            <UserButtonStyled
                                active={userType === 'fan'}
                                type="button"
                                onClick={() => {
                                    setUserType('fan');
                                }}
                            >
                                {userType === 'fan' && (
                                    <CheckStyled>
                                        <CheckIcon />
                                    </CheckStyled>
                                )}
                                Fan
                            </UserButtonStyled>
                        </UserButtonsStyled>
                        {userType === 'fan' && (
                            <SignupCtaStyled>
                                Trying to join an existing room?{' '}
                                <TextButton onClick={onSignup} type="button">
                                    Sign up now
                                </TextButton>
                            </SignupCtaStyled>
                        )}
                    </>
                )}
                {step === 3 && (
                    <>
                        <HeadingStyled>What’s your top social channel?</HeadingStyled>
                        <TextInputContainerStyled>
                            <TextInputStyled
                                label="Top social channel"
                                name="social"
                                inputSize="lg"
                                type="text"
                                onChange={(e) => {
                                    setSocial(e.target.value);
                                    clearError('social');
                                }}
                                placeholder="https://twitter.com/KingJames"
                                hasError={errors.has('social')}
                            />
                            {errors.has('social') && (
                                <TextInputErrorStyled>
                                    Looks like you forgot to enter a link.
                                </TextInputErrorStyled>
                            )}
                        </TextInputContainerStyled>
                    </>
                )}
                {step === 4 && (
                    <>
                        <HeadingStyled>How did you find out about Playback?</HeadingStyled>
                        <TextInputContainerStyled>
                            <TextInputStyled
                                label="Referral source"
                                name="referralSource"
                                inputSize="lg"
                                type="text"
                                onChange={(e) => {
                                    setReferralSource(e.target.value);
                                }}
                                placeholder="What creator or social channel brought you here? (optional)"
                            />
                        </TextInputContainerStyled>
                        {state.errors.length > 0 && (
                            <ErrorStateStyled>
                                <AlertIcon />
                                Hmm, looks like that didn’t quite work! Please try again or email us
                                at contact@getplayback.com.
                            </ErrorStateStyled>
                        )}
                    </>
                )}
                {step === 5 && <BigHeadingStyled>Thanks! We’ll be in touch soon.</BigHeadingStyled>}
            </ContentsStyled>
            {step === 5 ? (
                <ButtonsStyled>
                    <DarkButtonStyled
                        type="button"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Close
                    </DarkButtonStyled>
                </ButtonsStyled>
            ) : (
                <ButtonsStyled>
                    {step > 1 && (
                        <BackButtonStyled
                            type="button"
                            disabled={state.submitting || userType === 'fan'}
                            onClick={() => {
                                setStep(step - 1);
                            }}
                        >
                            <ArrowLeft />
                            Back
                        </BackButtonStyled>
                    )}
                    <RedButtonStyled
                        type="submit"
                        disabled={state.submitting || userType === 'fan'}
                    >
                        {step === 4 ? 'Done' : 'Next'}
                        <ArrowRight />
                    </RedButtonStyled>
                </ButtonsStyled>
            )}
            <ProgressBarStyled
                format="negative"
                autoStart
                started
                completed={false}
                duration={250}
                preCompletionMaxPercent={(step / 5) * 100}
            />
        </CreatorApplicationStyled>
    );
};

const SignupCtaStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ theme }) => theme.typography.Paragraph16}
    color: ${({ theme }) => transparentize(0.4, theme.palette.Pink)};
    margin-top: ${rem(20)};
    flex-wrap: wrap;

    button {
        font-size: ${rem(16)};
        margin-left: ${rem(4)};
        text-align: left;
        white-space: nowrap;
        flex-shrink: 0;
    }
`;

const ErrorStateStyled = styled.div`
    border-radius: ${rem(12)};
    padding: ${rem(12)} ${rem(16)};
    display: flex;
    align-items: flex-start;
    background-color: ${({ theme }) => theme.palette.Red};
    ${({ theme }) => theme.typography.Paragraph13}
    color: ${({ theme }) => theme.palette.White};
    margin-top: ${rem(16)};

    svg {
        height: ${rem(18)};
        width: ${rem(18)};
        flex-shrink: 0;
        margin-right: ${rem(8)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        ${({ theme }) => theme.typography.Paragraph16}
        color: ${({ theme }) => theme.palette.White};
        margin-top: ${rem(28)};
        padding: ${rem(16)} ${rem(20)};

        svg {
            height: ${rem(24)};
            width: ${rem(24)};
            margin-right: ${rem(16)};
        }
    }
`;

const HeadingStyled = styled(Heading2Styled)`
    margin-bottom: ${rem(40)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-bottom: ${rem(48)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        margin-bottom: ${rem(64)};
    }
`;

const BigHeadingStyled = styled(HeadingStyled)`
    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        font-size: ${rem(72)};
    }

    margin-bottom: 0;
`;

const UserButtonsStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-self: center;
    }
`;

const UserButtonStyled = styled.button<{ active: boolean }>`
    height: ${rem(64)};
    border-radius: ${rem(32)};
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    padding: 0 ${rem(32)};
    width: 100%;
    ${({ theme }) => theme.typography.Heading16}

    ${CheckStyled} {
        margin-right: ${rem(8)};
    }

    & + & {
        margin-top: ${rem(12)};
    }

    ${({ active, theme }) =>
        active &&
        css`
            box-shadow: inset 0px 0px 0px ${rem(2)} ${theme.palette.White};
        `}

    @media (hover: hover) {
        &:hover {
            background-color: rgba(0, 0, 0, 0.6);
        }
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        justify-content: center;
        width: auto;

        & + & {
            margin-top: 0;
            margin-left: ${rem(16)};
        }
    }
`;

const TextInputErrorStyled = styled.p`
    text-align: left;
    ${({ theme }) => theme.typography.Paragraph16}
    color: ${({ theme }) => theme.palette.Red};
    margin-top: ${rem(12)};
`;

const TextInputContainerStyled = styled.div`
    align-self: stretch;

    & + & {
        margin-top: ${rem(24)};
    }
`;

const BackButtonStyled = styled(DarkButtonStyled)`
    svg {
        margin-left: 0;
        margin-right: ${rem(8)};
    }
`;

const ButtonsStyled = styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;

    * + * {
        margin-left: ${rem(20)};
        flex: 1;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        align-self: center;

        * + * {
            flex: unset;
        }
    }
`;

const TextInputStyled = styled(TextInput)`
    width: 100%;

    label {
        color: ${({ theme }) => theme.palette.White};
    }

    input {
        background-color: rgba(0, 0, 0, 0.3);

        &:focus {
            background-color: rgba(0, 0, 0, 0.6);
        }
    }
`;

const ProgressBarStyled = styled(ProgressBar)`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    * {
        border-radius: 0;
    }
`;

const ContentsStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: ${rem(540)};
    padding: ${rem(40)} 0 ${rem(80)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        max-width: ${rem(640)};
    }
`;

const CreatorApplicationStyled = styled.form`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: ${rem(32)};
    padding-top: 0;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        padding: ${rem(40)};
        padding-top: 0;
    }
`;

export default CreatorApplication;
